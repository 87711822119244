import AppSettings from '../entity/AppSettings'

export default class AppSettingsProvider {
    constructor(element = document.documentElement) {
        this.appSettings = JSON.parse(element.getAttribute('data-app-settings'));
    }

    getAppSettings() {
        return new AppSettings(
            this.appSettings.google_analytics_tracking_id,
            this.appSettings.google_places_api_key,
            this.appSettings.sentry_js_dsn,
            this.appSettings.poxa_public_host,
            this.appSettings.poxa_port,
            this.appSettings.internal_domain,
        );
    }
}
