import parser from 'url-parse';
import Routing from '%vendor/friendsofsymfony/jsrouting-bundle/Resources/js/router';
import routes from '%web/js/fos_js_routes.json';
import { get, omit } from 'lodash';
import LocalizationStorage from './localizationStorage';
import externalRoutes from '../constants/externalRoutes';

Routing.setRoutingData(routes);

export default class Router {
    generate(name, params, absolute) {
        if (externalRoutes.includes(name)) {
            return Routing.generate(
                name,
                { _locale: LocalizationStorage.getCurrentLanguage(), ...params },
                absolute,
            );
        }

        const locale = get(params, '_locale', LocalizationStorage.getCurrentLanguage());
        const country = get(params, '_country', LocalizationStorage.getCurrentCountry());

        const path = Routing.generate(
            name,
            { ...omit(params, ['_locale', '_country']) },
            absolute,
        );

        let localizationContext = locale;
        if (country !== null) {
            localizationContext = `${localizationContext}-${country}`;
        }

        if (absolute) {
            const url = parser(path, {});
            url.set('pathname', `${localizationContext}${url.pathname}`);

            return url.toString();
        }

        return `/${localizationContext}${path}`;
    }

    replaceLocationLocalization(location, locale, country) {
        const pureLocation = location.replace(/^\/[a-z]{2}(?:-[A-Z]{2})?/, '').replace(/\/$/, '');
        let prefix = `/${locale}`;
        if (country !== null) {
            prefix = `${prefix}-${country}`;
        }

        return pureLocation.length > 0 ? `${prefix}${pureLocation}` : prefix;
    }
}

const router = new Router();

export { router };
