// please use param `?with=version` in sync with `cacheVersion` in the `service-worker.js`
const scriptURL = '/service-worker.js?with=v5';

async function register() {
    await navigator.serviceWorker.register(scriptURL);
}

async function unregister() {
    const registrations = await navigator.serviceWorker.getRegistrations();
    const promises = registrations.map((registration) => {
        if (registration.active.state === 'activated') {
            const regexp = new RegExp(`${scriptURL}$`);
            if (!registration.active.scriptURL.match(regexp)) {
                return registration.unregister();
            }
        }

        return undefined;
    });

    return Promise.all(promises);
}

async function addServiceWorker() {
    await unregister();
    await register();
}

if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        addServiceWorker();
    });
}
