class CurrentUserSettingsProvider {
    constructor() {
        this.settings = {};
        this.initSettings();
    }

    initSettings() {
        const settings = document.documentElement.getAttribute('data-user');
        if (settings !== null && settings !== '') {
            this.settings = JSON.parse(settings);
        }
    }

    get(setting) {
        if (typeof this.settings[setting] !== 'undefined') {
            return this.settings[setting];
        }

        return null;
    }

    getUserFullName() {
        let username = '';
        if (this.get('first_name') !== null) {
            username = this.get('first_name');
        }
        if (this.get('last_name') !== null) {
            username = (`${username} ${this.get('last_name')}`).trim();
        }

        return username;
    }

    getUserShortName() {
        let username = '';
        if (this.get('first_name') !== null) {
            username = this.get('first_name');
        }
        if (this.get('last_name') !== null) {
            username = (`${username} ${this.get('last_name').charAt(0)}.`).trim();
        }

        return username;
    }

    getUserLanguage() {
        const language = document.documentElement.getAttribute('lang');
        if (language !== null && language !== '') {
            return language;
        }

        return null;
    }

    getUserDataCountry() {
        const country = document.documentElement.getAttribute('data-country');
        if (country !== null && country !== '') {
            return country;
        }

        return null;
    }

    getUserHash() {
        return this.get('user_hash');
    }
}

export default new CurrentUserSettingsProvider();
