import jQuery from 'jquery';
import 'select2/dist/js/select2.full';
import { router } from '%app/js/common/services/router';
import LocalizationStorage from '%app/js/common/services/localizationStorage';
import globeImage from '%evp_ticket_frontend/images/globe.svg';

class LocalizationHelper {
    constructor() {
        this.parent = '.localization-wrapper .modal';
        this.regionSelector = '.localization-wrapper #localization-region-selector';
        this.languageSelector = '.localization-wrapper #localization-language-selector';
        this.confirmButtonSelector = '.localization-wrapper #localization-confirm';
        this.resetToInternationalButtonSelector = '.localization-wrapper #localization-reset-to-international';
    }

    formatOption({ id, text }) {
        if (!id) {
            return text;
        }
        if (id === 'null') {
            return jQuery(`<span><img src="${globeImage}" class="flag-icon"/>${text}</span>`);
        }
        return jQuery(`<span><span class="flag-icon flag-icon-${id.toLowerCase()}"/>${text}</span>`);
    }

    getUrlWithChangedLocalization(language, region) {
        return router.replaceLocationLocalization(
            window.location.pathname,
            language,
            region,
        );
    }

    init() {
        const languageSelector = jQuery(this.languageSelector);
        const regionSelector = jQuery(this.regionSelector);

        languageSelector.select2({
            width: '100%',
            dropdownParent: jQuery(this.parent),
            language: LocalizationStorage.getCurrentLanguage(),
        });
        regionSelector.select2({
            width: '100%',
            dropdownParent: jQuery(this.parent),
            language: LocalizationStorage.getCurrentLanguage(),
            templateResult: this.formatOption,
            templateSelection: this.formatOption,
        });

        jQuery(this.confirmButtonSelector).on('click', () => {
            const language = languageSelector.val();
            let region = regionSelector.val();
            if (region === 'null') {
                region = null;
            }
            window.location.href = this.getUrlWithChangedLocalization(language, region);
        });
        jQuery(this.resetToInternationalButtonSelector).on('click', () => {
            window.location.href = this.getUrlWithChangedLocalization(
                languageSelector.data('fallback-locale'),
                null,
            );
        });
    }
}

jQuery(document).ready(() => {
    new LocalizationHelper().init();
});
