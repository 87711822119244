import currentUserSettingsProvider from '%paysera_admin_frontend/js/common/service/currentUserSettingsProvider';

export default class LocalizationStorage {
    setLanguage(value) {
        this.language = value;
    }

    getLanguage() {
        if (!this.language) {
            this.language = LocalizationStorage.getCurrentLanguage();
        }

        return this.language;
    }

    setCountry(value) {
        this.country = value;
    }

    getCountry() {
        if (!this.country) {
            this.country = LocalizationStorage.getCurrentCountry();
        }

        return this.country;
    }

    static getCurrentLanguage() {
        const language = currentUserSettingsProvider.getUserLanguage();
        if (environment_config.available_locales.includes(language)) {
            return language;
        }

        return environment_config.fallback_locale;
    }

    static getFallbackLanguage() {
        return environment_config.fallback_locale;
    }

    static getGoogleLanguage() {
        const language = LocalizationStorage.getCurrentLanguage();
        const map = {
            xk: "sq"
        };

        return map[language] || language;
    }

    static isCurrentLanguageRtl() {
        return LocalizationStorage.isRtlLanguage(LocalizationStorage.getCurrentLanguage());
    }

    static isRtlLanguage(language) {
        return environment_config.rtl_locales.includes(language);
    }

    static getCurrentCountry() {
        return currentUserSettingsProvider.getUserDataCountry();
    }

    static getLocalizationContext() {
        let context = LocalizationStorage.getCurrentLanguage();
        if (LocalizationStorage.getCurrentCountry() !== null) {
            context = `${context}-${LocalizationStorage.getCurrentCountry()}`;
        }

        return context;
    }
}
