export default [
    'paysera_admin.external_route.login_to_paysera',
    'paysera_admin.external_route.hepdesk.create_paysera_project',
    'paysera_admin.external_route.hepdesk.create_discount',
    'paysera_admin.external_route.hepdesk.validate_tickets',
    'paysera_admin.external_route.hepdesk.setup_examiner',
    'paysera_admin.external_route.hepdesk.faq',
    'paysera_admin.external_route.hepdesk.create_invitation',
    'paysera_admin.external_route.hepdesk.custom_fields',
    'paysera_admin.external_route.hepdesk.event_widget',
    'paysera_admin.external_route.hepdesk.refund_payment',
    'paysera_admin.external_route.hepdesk.locate_project_id_and_sign',
    'paysera_admin.external_route.hepdesk.create_event',
    'paysera_admin.external_route.hepdesk.login',
    'paysera_admin.external_route.google_support.analytics_id',
    'paysera_admin.external_route.google_support.conversion_id',
    'paysera_admin.external_route.facebook_help.facebook_pixel',
    'paysera_admin.external_route.google_support.container_id',
    'paysera_admin.external_route.link_payments_via_internet',
    'paysera_admin.external_route.paysera_cs_contacts',
    'paysera_admin.external_route.eu_consumer_centre_dispute_resolution',
    'paysera_admin.external_route.paysera_privacy_policy',
];
