import jQuery from 'jquery';

class InvoiceDetailsHelper {
    constructor() {
        this.container = '.invoice-details-form';
        this.translations = {};
        this.typeTarget = "input[name='invoice_details_fill_form[receiverType]']:checked";
        this.vatTarget = "input[name='invoice_details_fill_form[paysVat]']:checked";
        this.nameLabelTarget = "label[for='invoice_details_fill_form_name']";
        this.codeLabelTarget = "label[for='invoice_details_fill_form_code']";
        this.vatCodeFieldTarget = "input[name='invoice_details_fill_form[vatCode]']";
    }

    processTypeChanges(element) {
        let value = element.value;
        if (value === undefined) {
            value = element.val();
        }

        jQuery(this.nameLabelTarget).text(this.translations[value]['name']);
        jQuery(this.codeLabelTarget).text(this.translations[value]['code']);
    }

    processVatChanges(element) {
        let value = element.value;
        if (value === undefined) {
            value = element.val();
        }

        const field = jQuery(this.vatCodeFieldTarget);
        if (parseInt(value) === 1) {
            field.parent().removeClass('hidden');
        } else {
            field.parent().addClass('hidden');
            field.val('');
        }
    }

    init() {
        const container = jQuery(this.container);
        if (container.length === 0) {
            return;
        }

        this.translations = container.data('invoice-details-helper-translations');
        const self = this;
        jQuery('body')
            .on('change', this.typeTarget, null, function () {
                self.processTypeChanges(this);
            })
            .on('change', this.vatTarget, null, function () {
                self.processVatChanges(this);
            })
        ;

        this.processTypeChanges(jQuery(this.typeTarget));
        this.processVatChanges(jQuery(this.vatTarget));

        jQuery(this.container).submit(function () {
            jQuery(this).find("button[type='submit']").prop('disabled', true);
        });
    }
}

const invoiceDetailsHelper = new InvoiceDetailsHelper();

jQuery(document).ready(() => {
    invoiceDetailsHelper.init();
});
