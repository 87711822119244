import jQuery from 'jquery';
import { router as Router } from '%app/js/common/services/router';
import loadGoogleMapsApi from 'load-google-maps-api-2';
import LocalizationStorage from '%app/js/common/services/localizationStorage';
import AppSettingsProvider from '%app/js/common/services/AppSettingsProvider';

import 'select2/dist/js/select2.full';
import 'eonasdan-bootstrap-datetimepicker/src/js/bootstrap-datetimepicker';

const currentLanguage = LocalizationStorage.getCurrentLanguage();
const replacedCurrentLanguage = currentLanguage === 'xk' ? 'sq' : currentLanguage;
require(`select2/dist/js/i18n/${replacedCurrentLanguage}.js`);

class SearchWidgetsManager {
    constructor() {
        this.datepickerFields = [
            '.event-search-form .search-field-date_from .form-control',
            '.event-search-form .search-field-date_to .form-control',
        ];
        this.select2Fields = [
            '.event-search-form .search-field-category .form-control',
        ];
        this.autocompleteField = {
            selector: '.event-search-form .search-field-event .form-control',
            route: 'evp_ticket_frontend.event_search.autocomplete.event_by_term',
        };
        this.googleMapAutocompleteField = '.event-search-form .search-field-location .form-control';
        this.mapsAutocomplete = null;
        this.locationPlaceIdField = '.event-search-form .search-field-place_id';
        this.localeContainer = '.event-search-form .locale-container';
        this.formContainer = '#event-search-form';
        this.formContainerCollapseBtn = '#event-search-form-collapse-btn';
        this.toggledIcon = '.event-search-form .toggled-icon';

        this.toggledOpenClass = 'fa-angle-double-up';
        this.toggledClosedClass = 'fa-angle-double-down';

        this.locales = null;
        this.autocompleteTemplate = `
        <div class="autocomplete-item">
            <p class="item-name"><bdo dir="auto">%name%</bdo></p>
            <div class="item-date">%date%</div>
            <div class="item-location"><bdo dir="auto">%location%</bdo></div>
        </div>`;
        this.selectedEventRoute = 'evp_ticket_frontend.single_event.event_info';
        this.mapsApiKey = (new AppSettingsProvider()).getAppSettings().getGooglePlacesApiKey();
    }

    configureDatepickerFields() {
        jQuery.each(this.datepickerFields, (i, selector) => {
            jQuery(selector)
                .datetimepicker({
                    format: 'YYYY-MM-DD',
                    useCurrent: false,
                    language: this.locales.moment,
                    icons: {
                        up: 'fa fa-chevron-left',
                        down: 'fa fa-chevron-right',
                    },
                    pickTime: false,
                })
                .attr('autocomplete', 'off');
        });
    }

    configureSelect2Fields() {
        jQuery.each(this.select2Fields, (i, selector) => {
            jQuery(selector).select2({
                language: this.locales.select2,
                allowClear: true,
            });
        });
    }

    configureAutocompleteFields() {
        jQuery(this.autocompleteField.selector).select2({
            language: this.locales.select2,
            allowClear: true,
            minimumInputLength: 4,
            ajax: {
                url: Router.generate(this.autocompleteField.route),
                dataType: 'json',
                delay: 500,
                cache: false,
            },
            dropdownCssClass: 'event-search-form',
            templateResult: (event) => {
                if (!event.id || event.id === event.text) {
                    return event.text;
                }

                const template = this.autocompleteTemplate
                    .replace('%name%', event.text)
                    .replace('%date%', event.date_starts)
                    .replace('%location%', event.location);
                return jQuery(template);
            },
            tags: true,
        });
    }

    initializeAutocomplete(googleMaps) {
        // resolve(window.google.maps)
        const field = jQuery(this.googleMapAutocompleteField).get(0);
        this.mapsAutocomplete = new googleMaps.places.Autocomplete(
            field,
            {
                types: [
                    'geocode',
                    'establishment',
                ],
                fields: [
                    'name',
                    'geometry.location',
                    'place_id',
                    'formatted_address',
                ],
            },
        );
        field.addEventListener('keydown', (e) => {
            if (e.key === 'Enter') {
                e.preventDefault();
            }
        });
        this.mapsAutocomplete.addListener('place_changed', () => { this.processChangedPlace(); });
    }

    processChangedPlace() {
        const place = this.mapsAutocomplete.getPlace();
        jQuery(this.googleMapAutocompleteField).val(place.name);
        jQuery(this.locationPlaceIdField).val(place.place_id);
    }

    init() {
        this.locales = jQuery(this.localeContainer).data('locales');

        jQuery(this.googleMapAutocompleteField).on('blur', () => {
            jQuery(this.googleMapAutocompleteField).val(null);
        });

        this.configureDatepickerFields();
        this.configureSelect2Fields();
        this.configureAutocompleteFields();

        jQuery('body').on('select2:select', this.autocompleteField.selector, null, (event) => {
            if (event.params.data.slug) {
                window.location.href = Router.generate(this.selectedEventRoute, {
                    slug: event.params.data.slug,
                });
            }
        });

        const formContainer = jQuery(this.formContainer);
        formContainer.on('hidden.bs.collapse', () => {
            jQuery(this.toggledIcon)
                .removeClass(this.toggledOpenClass)
                .addClass(this.toggledClosedClass);
        });
        formContainer.on('shown.bs.collapse', () => {
            jQuery(this.toggledIcon)
                .removeClass(this.toggledClosedClass)
                .addClass(this.toggledOpenClass);
        });

        jQuery(this.formContainerCollapseBtn).click(() => {
            formContainer.collapse('toggle');
        });

        loadGoogleMapsApi({
            key: this.mapsApiKey,
            libraries: ['places'],
            language: new LocalizationStorage().getLanguage(),
        })
            .then((googleMaps) => {
                this.initializeAutocomplete(googleMaps);
            });
    }
}

jQuery(document).ready(() => {
    new SearchWidgetsManager().init();
});
