export default class AppSettings {
    constructor(
        googleAnalyticsTrackingId,
        googlePlacesApiKey,
        sentryJsDsn,
        poxaPublicHost,
        poxaPort,
        internalDomain,
    ) {
        this.googleAnalyticsTrackingId = googleAnalyticsTrackingId;
        this.googlePlacesApiKey = googlePlacesApiKey;
        this.sentryJsDsn = sentryJsDsn;
        this.poxaPublicHost = poxaPublicHost;
        this.poxaPort = poxaPort;
        this.internalDomain = internalDomain;
    }

    getGoogleAnalyticsTrackingId() {
        return this.googleAnalyticsTrackingId;
    }

    getGooglePlacesApiKey() {
        return this.googlePlacesApiKey;
    }

    getSentryJsDsn() {
        return this.sentryJsDsn;
    }

    getPoxaPublicHost() {
        return this.poxaPublicHost;
    }

    getPoxaPort() {
        return this.poxaPort;
    }

    getInternalDomain() {
        return this.internalDomain;
    }
}
